import { render, staticRenderFns } from "./TextAreaValidation.vue?vue&type=template&id=4b80a4f6&"
import script from "./TextAreaValidation.vue?vue&type=script&lang=js&"
export * from "./TextAreaValidation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports