<template>
  <div>
    <div :id="name" class="multiselect__container">
      <div v-if="isSearchable" class="multiselect__input-wrapper">
        <BFormInput class="multiselect__search-input" v-model="search" @input="searchHandler" :placeholder="placeholder" />
        <BSpinner v-if="isSearching" class="multiselect__loader" variant="primary" small />
      </div>
      <div class="multiselect__items">
        <vue-simple-scrollbar ref="scroll">
          <div ref="list" class="multiselect__items-wrapper">
              <div v-for="region in facets" class="d-flex mb-1 justify-content-between align-items-center" :id="region.id">
                <p>{{region.name}}</p>
                <b-col sm="4">
<!--                <BFormInput placeholder="Запросов" type="number" v-model="state[region.id]" />-->
                  <InputModel  placeholder="В сутки" placeholder-global="На РК" :model-value="state[region.id]" @update-val="setLimit($event, region.id)"/>
                </b-col>
              </div>
<!--              <BFormCheckbox v-for="checkbox in facets" :value="checkbox.id">{{checkbox.name}}</BFormCheckbox>-->
          </div>
        </vue-simple-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormCheckboxGroup,BFormCheckbox, BFormInput, BSpinner} from "bootstrap-vue"
import VueSimpleScrollbar from 'vue-simple-scrollbar'
import '../../node_modules/vue-simple-scrollbar/dist/vue-simple-scrollbar.css'
import InputModel from "@/components/InputModel"

export default {
  name: 'RegionSelect',
  components: {
    InputModel,
    BFormCheckboxGroup,
    BFormInput,
    BSpinner,
    BFormCheckbox,
    VueSimpleScrollbar
  },

  props: {
    state: {
      type: Object,
      default: () => {},
    },
    facets: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Введите несколько символов для поиска',
    },
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    onChange: Function,
    trackBy: {
      type: String,
      default: 'pk',
    },
    labelName: {
      type: String,
      default: 'name',
    },
    isPaginated: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    lists: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      value: [],
      search: '',
      opened: true,
      searchValue: '',
      ajaxOptions: [],
      isSearching: false,
      isTriedToSearch: false,
      geo_limits: {},
    };
  },

  watch: {
    facets() {
      this.isSearching = false;
    }
  },

  computed: {
    proxyValue() {
      if(Array.isArray(this.value)) return this.value;
      if(!this.value) return [];

      const arr = [];
      arr.push(this.value)
      return arr;
    },
    valueArray() {
      let arr = [];

      if (Array.isArray(this.proxyValue)) {
        this.proxyValue.forEach(item => {
          arr.push(item[this.trackBy]);
        });
      } else {
        arr.push(this.proxyValue[this.trackBy]);
      }

      return arr;
    },

    valueList() {
      let label = '';

      this.proxyValue.forEach(item => {
        if (this.facets.includes(item[this.trackBy]))
          label += `${item[this.labelName]}, `;
      });

      if (!label) {
        label = this.proxyValue.reduce((acc, item) => acc += `${item[this.labelName]}, `, '');
      }

      return label.slice(0, -2);
    },

    initialValue() {
      if (!(this.facets && this.facets.length) || !(this.state && this.state.length)) return []
      return [].concat.apply([], this.facets.filter(opt => this.state.includes(opt[this.trackBy])));
    },
  },

  created() {
    this.$parent.$on('resetFilters', () => {
      this.value = [];
    })

    if(this.isAjaxSearchable) {
      this.initAjaxOptions();
    }

    if (this.state && this.state.length) {
      this.value = this.state
    }
  },

  // updated() {
  //   if (this.initialValue && this.initialValue.length) {
  //     this.value = this.initialValue.map(item => item[this.trackBy]);
  //   }
  // },

  mounted() {
    if(this.isPaginated) {
      this.$nextTick(() => {
        const ssContent = this.$refs['scroll'].$el["data-simple-scrollbar"].el;
        const list = this.$refs['list'].$el;

        const observer = (e) => {
          if((e.target.scrollTop + e.target.clientHeight >= this.$refs['list'].clientHeight) && !this.isSearching) {
            if(!this.lists[this.name][1].value) return;
            this.isSearching = true;
            this.$emit('paginate', this.name);
          }
        }

        ssContent.addEventListener('scroll', observer)


      })
    }
  },

  methods: {
    // inputLimit(e, id) {
    //   this.geo_limits[id] = e;
    // },

    setLimit(e, name) {
      this.state[name] = e;
    },

    changeHandler(e) {
      if (this.onChange) this.onChange(this.value, this.name);
    },

    searchHandler(searchQuery) {
      if (!(searchQuery === '' || searchQuery.length >= 2)) return;
      this.isSearching = true;
      if (searchQuery === '') {
        this.$emit('search', [this.name, '', this.value.join(',')])
      } else {
        this.$emit('search', [this.name, searchQuery])
      }
    },

    initScroll() {
      let optionListWrap = this.$refs['list'];
      optionListWrap.setAttribute('ss-container', true);
      SimpleScrollbar.initAll();
    },
  },
};
</script>

<style lang="scss" scoped>

.multiselect {

  &__container {
    max-height: 300px;
    border-radius: 0.357rem;
  }

  &__search-input {
    font-size: 16px;
    border: 1px solid #d8d6de;
  }

  &__items {
    height: 215px;
  }

  &__items-wrapper {
    margin-top: 12px;
    padding-left: 12px;
  }

  &__loader {
    position: absolute;
    top: 35%;
    right: 16px;
  }

  &__input-wrapper {
    position: relative;
  }
}
</style>

<style lang="scss">
.multiselect {
  &__items-wrapper {

    & .custom-checkbox {
      margin-bottom: 8px;
    }
  }
}
</style>

