<template>
  <div>

    <transition name="fade">
    <campaign-full-page-form v-if="isOpenCreateForm" title="Добавить РК" @close="isOpenCreateForm = false; copyCampaign = null">
            <create-campaign-form v-if="isOpenCreateForm" @success="handleCreate" @error="handleError" :data="{campaign: copyCampaign}" />
    </campaign-full-page-form>
    </transition>

    <transition name="fade">
      <campaign-full-page-form v-if="!!editCampaign" title="Редактировать РК" @close="editCampaign = null">
        <create-campaign-form @success="handleUpdate" @error="handleError" :data="{ campaign: editCampaign }" />
      </campaign-full-page-form>
    </transition>

    <transition name="fade">
    <b-card v-if="!isLoadingFilters && !isOpenCreateForm && !editCampaign" no-body>
      <b-row class="my-2 px-2 justify-content-between">
        <b-col sm="2" class="mb-1 mb-md-0">
          <b-form-select v-model="queryObj.is_active" :options="activeOptions" @change="setIsActive" />
        </b-col>
        <b-col v-if="can('read', 'advertisers-filter')" sm="3" class="mb-1 mb-md-0">
          <b-input-group>
            <DropDownFilter :on-change="setDsp"
                            :facets="advertisersList"
                            :state="queryObj.user_id"
                            is-paginated
                            @paginate="handlePaginateList"
                            track-by="id"  name="dsps" placeholder="Выберите DSP"/>
            <b-input-group-append is-text>
              <CustomIcons name="dsps" />
            </b-input-group-append>
          </b-input-group>

        </b-col>
        <b-col v-if="can('read', 'advertisers-filter')" sm="3" class="mb-1 mb-md-0">
          <b-input-group>
            <DropDownFilter name="placements"
                            :on-change="setFilter"
                            :facets="placementsList"
                            :state="queryObj.placements"
                            is-paginated
                            @paginate="handlePaginateList"
                            track-by="id" placeholder="Выберите размещения"/>
            <b-input-group-append is-text>
              <CustomIcons name="placements" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col sm="2" class="mb-1 mb-md-0">
          <b-input-group>
            <b-form-input placeholder="Поиск..." v-model="searchRef" @keyup="startSearch" />
            <b-input-group-append is-text>
              <b-icon-search></b-icon-search>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col v-if="can('create', 'advertising-campaigns')" class="d-flex justify-content-end " sm="1">
          <b-button variant="primary" @click="isOpenCreateForm = true">Создать</b-button>
        </b-col>
      </b-row>

      <div ref="scroll">
        <table-lazy :error="!!error" :is-loading="isLoading" :is-loading-more="isLoadingMore">
          <campaigns-table :campaigns="data" :sort-by="sortBy" :sort-desc="sortDesc" @sort-change="handleSortChange" @edit="handleEditUser" @toggle-active="handleAdvertisingToggleActive" @delete-item="handleDeleteCampaign"
          @copy-campaign="handleCopyCampaign($event); handleOpenCreateFormVisible()"/>
        </table-lazy>
      </div>
    </b-card>
    </transition>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButtonGroup, BButton, BFormSelect, BInputGroup, BIconSearch } from 'bootstrap-vue'
import DropDownFilter from "@/components/DropDownFilter"
import CampaignsTable from '@/components/CampaignsTable'
import CampaignForm from '@/components/CampaignForm'
import SidebarRight from '@/components/SidebarRight'
import TableLazy from '@/components/TableLazy'
import {getToastificationErrorParams, getToastificationSuccessParams} from '@/helpers/toasts'
import { withFormData } from '@/hoc/withFormData'
import {
  createCampaign,
  deleteCampaign,
  fetchCampaigns,
  activateCampaign,
  deactivateCampaign,
  patchCampaign
} from '@/api/campigns'
import {activeToggle} from "@/use/actions/activeToggle"
import { usePage } from '@/use/page'
import { CREATE_CAMPAIGN_VALIDATION } from '@/constants/validation'
import {infiniteScroll} from "@/use/infiniteScroll"
import {queries} from "@/use/queries"
import {search} from "@/use/search"
import {ref} from "@vue/composition-api"
import {useUtils as useAclUtils} from "@core/libs/acl"
import CampaignPlacementForm from "@/components/CampaignPlacementForm"
import CampaignFullPageForm from "@/components/CampaignFullPageForm"
import {objToQuery} from "@core/utils/utils"
import statisticLists from "@/use/statisticLists"
import CustomIcons from "@/components/CustomIcons"

const CreateCampaignForm = withFormData(CampaignForm, createCampaign, CREATE_CAMPAIGN_VALIDATION)
const ManagerPlacementForm = withFormData(CampaignPlacementForm, patchCampaign, CREATE_CAMPAIGN_VALIDATION);

export default {
  components: {
    CustomIcons,
    CampaignFullPageForm,
    DropDownFilter,
    SidebarRight,
    BCard,
    BInputGroup,
    BIconSearch,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BFormSelect,
    CampaignsTable,
    CreateCampaignForm,
    ManagerPlacementForm,
    TableLazy
  },
  data() {
    return {
      activeFilter: null,
      campaigns: [],
      isOpenCreateForm: false,
      editCampaign: null
    }
  },
  watch: {
    error(message) {
      this.$toast(getToastificationErrorParams(message))
    }
  },
  methods: {
    handleOpenCreateFormVisible() {
      this.isOpenCreateForm = true;
    },
    handleEditUser(id) {
      this.editCampaign = this.data.find((u) => u.id === id)
    },
    handleCreate() {
      this.isOpenCreateForm = false;
      this.searchRef = '';
      this.fetchPageData();
    },
    handleUpdate() {
      this.editCampaign = false;
      this.fetchPageData(objToQuery(this.queryObj));
    },
    handleError() {}
  },
  setup(_, ctx) {
    const { can } = useAclUtils()
    const store = ctx.root.$store;

    const activeOptions = [
      { value: null, text: 'Все кампании' },
      { value: 1, text: 'Активные' },
      { value: 0, text: 'Неактивные' }
    ]

    const { isLoading, data, error, loadMoreData, isLoadingMore, fetchPageData } = usePage(fetchCampaigns, _, ctx, store)
    const { scroll } = infiniteScroll(loadMoreData, isLoadingMore)
    const { queryObj } = queries(fetchPageData, ctx, store, 'campaigns')
    const { searchRef, startSearch } = search(queryObj, store, 'campaigns')
    const { handleToggleActive } = activeToggle()

    const sortBy = ref(null)
    const sortDesc = ref(null)


    const handleSortChange = (sort) => {
      console.log(sort);
      sortBy.value = sort.sortBy;
      sortDesc.value = sort.sortDesc;
      queryObj.field = sort.sortBy;
      queryObj.order = sort.sortDesc ? 'desc' : 'asc';
    }

    const copyCampaign = ref(null);

    const handleCopyCampaign = (id) => {
      copyCampaign.value = JSON.parse(JSON.stringify(data.value.find((u) => u.id === id)));
      copyCampaign.value.name += '_копия'
      copyCampaign.value.id = null;
      copyCampaign.value.copying = true;
    }

    const handleClearCopy = () => {
      copyCampaign.value = null;
    }

    const setDsp = (value) => {
      setFilter(value, 'user_id')
    }

    const setFilter = (value, name) => {
      queryObj[name] = value
      store.dispatch('campaigns/setFilter', {name: name, value: value})
    }

    const setIsActive = (value) => {
      store.dispatch('campaigns/setIsActive', value);
    }

    const setSearch = () => {
      startSearch();
      store.dispatch('campaigns/setSearch', searchRef.value);
    }

    const isLoadingFilters = ref(false);

    const {
      advertisersList,
        placementsList,
      handleLoadLists,
      handlePaginateList,
    } = statisticLists()


    const roleId = parseInt(localStorage.getItem('userRoleId'), 10)

    handleLoadLists(['dsps', 'placements'], isLoadingFilters);


    const handleDeleteCampaign = (id) => {
      deleteCampaign(id)
          .then(() => {
            this.$toast(getToastificationSuccessParams('РК удалена'))
      })
          .catch((e) => this.$toast(getToastificationErrorParams(e.message)))
    }

    const handleAdvertisingToggleActive = (data) => handleToggleActive(data, deactivateCampaign, activateCampaign)

    return { isLoading, data, error, loadMoreData, isLoadingMore, fetchPageData, scroll, queryObj, searchRef, startSearch,
      activeOptions,
      advertisersList,
      placementsList,
      handlePaginateList,
      setFilter,
      handleDeleteCampaign,
      handleAdvertisingToggleActive,
      isLoadingFilters,
      can,
      handleSortChange,
      sortBy,
      sortDesc,
      setDsp,
      setIsActive,
      setSearch,
      copyCampaign,
      handleCopyCampaign,
      handleClearCopy
    }
  }
}
</script>
