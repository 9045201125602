<template>
  <div class="d-flex" style="gap: 10px">
  <BFormInput ref="daily" :placeholder="placeholder" type="number" v-model="value[0]"/>

    <BFormInput ref="daily" :placeholder="placeholderGlobal" type="number" v-model="value[1]"/>

<!--  <BFormInput ref="global" :placeholder="placeholder" type="number" v-model="value" @input="value($event, 'global')" />-->
  </div>
</template>
<script>
import {BFormInput} from "bootstrap-vue"
export default {
  name: 'InputModel',
  props: {
    modelValue: {
      type: Array,
    },
    placeholder: String,
    placeholderGlobal: String,
  },
  emits: ['update:modelValue'],
  components: {
    BFormInput
  },
  created() {
    if(this.modelValue) {
      this.value = this.modelValue
    }
  },
  data() {
    return {
      value: [null, null],
    }
  },
  watch: {
    value() {
      this.$emit('update-val', this.value);
    }
  }
  // computed: {
  //   value: {
  //     get() {
  //       return this.modelValue
  //     },
  //     set(value, name) {
  //       const obj = {
  //         daily: this.$refs.daily.value,
  //         global: this.$refs.global.value
  //       }
  //       console.log(obj);
  //       this.$emit('update:model-value', obj);
  //     }
  //   }
  // }
}
</script>
