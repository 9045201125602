<template>
  <b-row class="min-height-360 mt-3 campaign-form__regions">
  <b-col class="campaign-form__multiselect-leftcol" id="to-regions" sm="3">
    <label for="to-regions" class="campaign-form__multiselect-label">Региональное распределение</label>
    <CountrySelect :model-value="country" @update:model-value="setCountry"
                    :filters-options="geoList"
    />
  </b-col>
    <b-col sm="9">
      <b-row class="d-flex" style="gap: 20px">
        <div class="region_add btn btn-primary btn-block" @click="addRegion">{{!this.isAdding ? 'Добавить регион' : 'Отмена'}}</div>
        <div class="region-distribution__items">
            <div v-if="isAdding" class="row mb-1">
              <b-col sm="5">
                <DropDownFilter :on-change="setNewRegion"
                                :facets="regionsListSorted"
                                :state="newRegion"
                                is-paginated
                                is-searchable
                                :multiple="false"
                                emit-with-name
                                @paginate="handlePaginateList"
                                @search="handleListsSearch"
                                track-by="id"  name="regions" placeholder="Выберите регион"/>
              </b-col>
              <b-col sm="7" class="d-flex">
              <input class="form-control" type="number" v-model="newLimit" :min="0" :max="100" @input="validateNewLimit">
              <BButton variant="primary" @click="addNewRegion">Добавить</BButton>
              </b-col>
            </div>

          <div class="region__rest d-flex mb-1 justify-content-between align-items-center" v-for="item in distributionData">
            <p class="region__rest-title">{{item.name}}</p>
            <b-col sm="6" class="d-flex justify-content-between p-0">

            <BFormInput type="number" v-model="item.percent" class="mr-1" min="0" max="100"/>
              <BButton @click="deleteRegion(item.code)">Удалить</BButton>
            </b-col>
          </div>

          <div class="region__rest d-flex mb-1 justify-content-between align-items-center">
            <p class="region__rest-title">Остальные регионы</p>
            <b-col sm="6" class="d-flex p-0 justify-content-start">
            <BFormInput type="number" disabled v-model="restPercent"/>
            </b-col>
          </div>
        </div>
      </b-row>

      </b-col>
      </b-row>
</template>

<script>
import {ref} from "@vue/composition-api"
import CountrySelect from "@/components/CountrySelect"
import RegionSelect from "@/components/RegionSelect"
import statisticLists from "@/use/statisticLists"
import {fetchRegionsList} from "@/api/lists"
import {objToQuery} from "@core/utils/utils"
import formsLists from "@/use/formsLists"
import VueSimpleScrollbar from 'vue-simple-scrollbar'
import DropDownFilter from "@/components/DropDownFilter"

export default {
  name: 'RegionDistribution',
  components: {
    CountrySelect,
    DropDownFilter,
    RegionSelect,
    VueSimpleScrollbar
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      isAdding: false,
      newRegion: '',
      newLimit: null,
    }
  },

  computed: {
    restPercent() {
      const sum = this.distributionData.reduce((a, b) => a + Number(b.percent), 0);
      // if(this.isAdding) return 100 - this.newLimit - sum;
      return 100 - sum;
    },
    regionsListSorted() {
      const pickedRegions = this.formData.geo_distribution.map((item) => {
        return item.code
      });
      return this.regionsList.map((item) => {
        if(pickedRegions.includes(item.id)) {
          item.$isDisabled = true;
        }
        return item;
      })
    },
    distributionData() {
      if(!this.formData.geo_distribution) {
        return {
          REST: 100
        }
      }
      return this.formData.geo_distribution.sort((a, b) => b.percent - a.percent)
    }
  },

  methods: {
    addRegion() {
      this.isAdding = !this.isAdding;
      this.newLimit = null;
    },
    setNewRegion(value) {
      this.newRegion = value;
    },
    validateNewLimit(val) {
      if(this.newLimit >= this.restPercent){
        this.newLimit = this.restPercent
      }
    },
    addNewRegion() {
      const region = {
        name: this.newRegion.name,
        percent: this.newLimit,
        code: this.newRegion.id,
      }
      this.formData.geo_distribution.push(region)
      this.isAdding = false;
      this.newRegion = {};
      this.newLimit = 0;
    },
    deleteRegion(id) {
      this.formData.geo_distribution = this.formData.geo_distribution.filter((item) => {
        return item.code !== id
      })
      this.$forceUpdate();
    }
  },

  setup(props) {
    const {
      geoList,
      regionsList,
      namedLists,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList
    } = formsLists()

    const country = ref('RU');

    let regionsQuery = 'geo_id=RU';

    if(props.formData && props.formData.geo_distribution) {
      const regions = props.formData.geo_distribution.map((item) => {
        return item.code
      })
      regionsQuery += `&regions=${regions.join(',')}`
    }

    handleLoadLists([['geos', 'nameScope=Russia'], ['regions', regionsQuery]])


    const setCountry = (value, name) => {
      // isLoadingLists.value = true
      country.value = value;

      fetchRegionsList(objToQuery({'geo_id': [country.value]}))
          .then((res) => {
            regionsList.value = res.data.data
          })
    }

    return {
      geoList,
      regionsList,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList,
      country,
      setCountry,
      namedLists
    }
  }

}
</script>

<style lang="scss">
.region-distribution {
  &__items {
    width: 100%;
  }
}
</style>
