<template>
  <div v-if="isLoadingLists" class="d-flex justify-content-center align-items-center mt-5">
    <b-spinner variant="primary" label="Spinning" />
  </div>
  <validation-observer v-else ref="form" v-slot="{ invalid }">
    <BForm class="py-2 campaign-form" @submit.prevent="handleSubmit">
      <form-input class="campaign-form__input" id="name" label="Имя" v-model="formData.name" vid="name" :rules="validationSchema.name" />

      <form-input class="campaign-form__input" id="tag" label="VAST URL" v-model="formData.tag" vid="tag" :rules="validationSchema.tag" />

      <form-input-wrap
          id="advertiser_id"
          class="campaign-form__dsps"
          vid="advertiser_id"
          label="DSP"
          :rules="validationSchema.advertiser_id"
      >
      <MultiselectCheckboxes
          class="flex-1-1-0"
          :on-change="pickDsp"
          name="dsps"
          :facets="advertisersList"
          v-model="formData.advertiser_id"
          :state="formData.advertiser_id"
          track-by="id"
          is-paginated
          single
          :lists="namedLists"
          @paginate="handlePaginateList"
          @search="handleListsSearch"/>
      </form-input-wrap>

      <form-input-wrap
          id="advertisement_types"
          class="campaign-form__adv-types"
          vid="advertisement_types"
          label="Тип рекламы"
          :rules="validationSchema.advertisement_type_id"
      >
      <MultiselectCheckboxes
          class="flex-1-1-0"
          is-small
          :on-change="pickMultiselect"
          name="advertisement_types"
          :facets="advertisementTypesList"
          v-model="formData.advertisement_types"
          :state="formData.advertisement_types"
          track-by="id"
          :disable-method="disableForAdvertisementType"
          :is-searchable="false"
          @search="handleListsSearch"/>
      </form-input-wrap>

      <form-input class="campaign-form__input" id="daily_limit_user" label="Макс. запросов пользователя в сутки" v-model="formData.daily_limit_user" vid="daily_limit_user" :rules="validationSchema.daily_limit_user" />

      <form-input class="campaign-form__input" id="cpm" v-model="formData.main_cpm" type="number" vid="main_cpm" label="СРМ" :rules="validationSchema.main_cpm" />

      <form-input-wrap
          v-if="can('manage', 'campaign-weight')"
          class="campaign-form__input"
          id="weight"
          vid="weight"
          label="Вес РК"
          :rules="validationSchema.weight"
      >
        <b-form-input id="weight" v-model="formData.weight" />
      </form-input-wrap>

      <form-input-wrap
          v-if="can('manage', 'campaign-weight')"
          class="campaign-form__ips"
          id="max_daily_requests_per_ip"
          vid="max_daily_requests_per_ip"
          label="Макс. кол-во запросов с IP в сутки"
          :rules="validationSchema.max_daily_requests_per_ip"
      >
        <b-form-input id="max_daily_requests_per_ip" v-model="formData.max_daily_requests_per_ip" />
      </form-input-wrap>

      <form-input class="campaign-form__additional" id="additional_param" v-model="formData.additional_param" type="number" vid="additional_param" label="Доп. параметры запроса" />

      <form-input-wrap
          id="limit_init_day"
          class="campaign-form__input"
          vid="limit_init_day"
          label="Лимит запросов в сутки"
          :rules="validationSchema.limit_init_day"
      >
        <b-form-input id="limit_init_day" v-model="formData.limit_init_day" />
      </form-input-wrap>

      <form-input-wrap
          id="chance"
          class="campaign-form__chance"
          vid="chance"
          label="Шанс показа, %"
          :rules="validationSchema.chance"
      >
        <b-form-input id="chance" v-model="formData.chance" />
      </form-input-wrap>

      <form-input-wrap
          id="cookie_matching_id"
          class="campaign-form__cookie"
          vid="cookie_matching_id"
          label="Тип cookie matching"
          :rules="validationSchema.cookie_matching_id"
      >
        <b-form-select class="campaign-form__input" id="device_type_id" v-model="formData.cookie_matching_id">

          <b-form-select-option v-for="option in cookieMatchings" :value="option.id">
            {{ option.name }}
          </b-form-select-option>

        </b-form-select>
      </form-input-wrap>


      <b-row class="min-height-360 mt-2 campaign-form__multiselect">
        <b-col class="campaign-form__multiselect-leftcol" id="to-filter" md="4" lg="3">
          <label class="campaign-form__multiselect-label" for="to-filter">Таргетинги</label>
          <ToFilterSelect :model-value="filterToShow" @update:model-value="setFilterToShow"
                          :filters-options="filtersOptions" :state="formData"
                          :facets="namedLists" />
        </b-col>


        <b-col md="8" lg="9" class="campaign-form__select-col" v-show="filterToShow === 'device_types'">
          <b-row class="d-flex" style="gap: 20px">
            <MultiselectCheckboxes
                class="flex-1-1-0"
                :on-change="pickMultiselect"
                name="device_types"
                :facets="devicesList"
                :state="formData.device_types"
                track-by="id"
                :is-searchable="false"
                @search="handleListsSearch"/>
          </b-row>
        </b-col>


        <b-col md="8" lg="9"  class="campaign-form__select-col" v-show="filterToShow === 'geos'">
          <b-row class="d-flex">
            <MultiselectCheckboxes class="col-12 col-sm-6 mt-1 mt-md-0 mb-1 mb-md-0 pr-0"
                                   name="whitelist_geo"
                                   :on-change="pickMultiselect"
                                   :facets="geoWhiteList"
                                   :state="formData.whitelist_geo"
                                   label="Whitelist ГЕО"
                                   track-by="id"
                                   is-paginated
                                   :lists="namedLists"
                                   @paginate="handlePaginateList"
                                   @search="handleListsSearch"/>

            <MultiselectCheckboxes class="col-12 col-sm-6 pr-0"
                                   name="blacklist_geo"
                                   :on-change="pickMultiselect"
                                   :facets="geoBlackList"
                                   :state="formData.blacklist_geo"
                                   label="Blacklist ГЕО"
                                   track-by="id"
                                   is-paginated
                                   :lists="namedLists"
                                   @paginate="handlePaginateList"
                                   @search="handleListsSearch"/>
          </b-row>
        </b-col>

        <b-col md="8" lg="9" class="campaign-form__select-col" v-show="filterToShow === 'oses'">
          <b-row class="d-flex" style="gap: 20px">

            <MultiselectCheckboxes class="col pr-0 mt-1 mt-md-0"
                                   name="oses"
                                   :on-change="pickMultiselect"
                                   :facets="osList"
                                   :state="formData.oses"
                                   track-by="id"
                                   is-paginated
                                   :lists="namedLists"
                                   @paginate="handlePaginateList"
                                   @search="handleListsSearch"/>

          </b-row>
        </b-col>

        <b-col md="8" lg="9" class="campaign-form__select-col" v-show="filterToShow === 'browsers'">
          <b-row class="d-flex" style="gap: 20px">
            <MultiselectCheckboxes class="col pr-0 mt-1 mt-md-0"
                                   name="browsers"
                                   :on-change="pickMultiselect"
                                   :facets="browsersList"
                                   :state="formData.browsers"
                                   track-by="id"
                                   is-paginated
                                   :lists="namedLists"
                                   @paginate="handlePaginateList"
                                   @search="handleListsSearch"/>
          </b-row>
        </b-col>

        <b-col md="8" lg="9" class="campaign-form__select-col" v-show="filterToShow === 'list_domain'">
          <b-row class="d-flex" style="gap: 20px">
                  <div class="col pr-0 mt-1 mt-md-0">
                    <b-form-group label="Domain Blacklist / Domain Whitelist" v-slot="{ ariaDescribedby }">
                      <b-form-radio v-model="blacklistType" :aria-describedby="ariaDescribedby" name="some-radios" value="blacklist">Domain Blacklist</b-form-radio>
                      <b-form-radio v-model="blacklistType" :aria-describedby="ariaDescribedby" name="some-radios" value="whitelist">Domain Whitelist</b-form-radio>
                    </b-form-group>

                    <form-input-wrap
                        v-show="blacklistType === 'blacklist'"
                        id="domain_blacklist"
                        vid="domain_blacklist"
                        label="Blacklist Domain"
                        :rules="validationSchema.domain_blacklist"
                    >
                      <b-form-textarea id="domain_blacklist" v-model="formData.domain_blacklist" />
                    </form-input-wrap>

                    <form-input-wrap
                        v-show="blacklistType === 'whitelist'"
                        id="domain_whitelist"
                        vid="domain_whitelist"
                        label="Blacklist Domain"
                        :rules="validationSchema.domain_whitelist"
                    >
                      <b-form-textarea id="domain_whitelist" rows="3" v-model="formData.domain_whitelist" />
                    </form-input-wrap>
                  </div>
          </b-row>
        </b-col>

        <b-col md="8" lg="9" class="campaign-form__select-col" v-show="filterToShow === 'limits'">
          <RegionLimits :form-data="formData" />
        </b-col>

        <b-col md="8" lg="9" class="campaign-form__select-col" v-show="filterToShow === 'continuous_limit'">
          <b-row class="">
          <form-input-wrap
              id="max_impressions_per_day"
              class="campaign-form__input col-4"
              vid="max_impressions_per_day"
              label="Макс. кол-во просмотров за день"
              :rules="validationSchema.max_impressions_per_day + isContinuousRequired"
          >
            <b-form-input id="max_days_with_continuous_impressions" v-model="formData.max_impressions_per_day" />
          </form-input-wrap>
          </b-row>

          <b-row>
          <form-input-wrap
              id="max_days_with_continuous_impressions"
              class="campaign-form__input col-4"
              vid="max_days_with_continuous_impressions"
              label="Макс. кол-во дней просмотра подряд"
              :rules="validationSchema.max_days_with_continuous_impressions + isContinuousRequired"
          >
            <b-form-input id="max_days_with_continuous_impressions" v-model="formData.max_days_with_continuous_impressions" />
          </form-input-wrap>
          </b-row>

          <b-row>
          <form-input-wrap
              id="timeout_on_continuous_limit_reached"
              class="campaign-form__input col-4"
              vid="timeout_on_continuous_limit_reached"
              label="Таймаут по достижении лимита (сек)"
              :rules="validationSchema.timeout_on_continuous_limit_reached + isContinuousRequired"
          >
            <b-form-input id="timeout_on_continuous_limit_reached" v-model="formData.timeout_on_continuous_limit_reached" />
          </form-input-wrap>
          </b-row>


          <b-button @click="clearContinuousLimit">Очистить</b-button>

        </b-col>

        <b-col md="8" lg="9" class="campaign-form__select-col" v-show="filterToShow === 'distribution'">
          <RegionDistribution :form-data="formData" />
        </b-col>
      </b-row>

      <div class="pb-2 w-25 mx-auto campaign-form__submit">
        <loader-button
          type="submit"
          variant="primary"
          :disabled="isSubmitting"
          :is-submitting="isSubmitting"
        >
          {{ isEditor ? 'Сохранить' : 'Создать' }}
        </loader-button>
      </div>
    </BForm>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormInput,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormRadio,
  BFormGroup,
  BSpinner
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import FormInput from '@/components/FormInput'
import FormInputWrap from '@/components/FormInputWrap'
import LoaderButton from '@/components/LoaderButton'
import MultipleSelect from '@/components/MultipleSelect'
import Multiselect from 'vue-multiselect'
import {useUtils as useAclUtils} from "@core/libs/acl"
import ToFilterSelect from "@/components/ToFilterSelect"
import MultiselectCheckboxes from "@/components/MultiselectCheckboxes"

import * as validations from '@validations'
import { ValidationProvider } from 'vee-validate'
import {
  fetchAdvertisementType,
  fetchDeviceList,
  fetchCookieMatchingList
} from '@/api/lists'
import DropDownFilter from "@/components/DropDownFilter"
import {createCampaign, patchCampaign} from "@/api/campigns"
import MultiselectValidation from "@/components/MultiselectWithValidation"
import {getToastificationErrorParams, getToastificationSuccessParams} from "@/helpers/toasts"
import {ref} from "@vue/composition-api"
import RegionLimits from "@/components/RegionLimits"
import TextAreaValidation from "@/components/TextAreaValidation"
import formsLists from "@/use/formsLists"
import RegionDistribution from "@/components/RegionDistrubution"

export default {
  components: {
    RegionDistribution,
    TextAreaValidation,
    RegionLimits,
    ToFilterSelect,
    MultiselectCheckboxes,
    MultiselectValidation,
    ValidationProvider,
    BForm,
    BButton,
    BFormRadio,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BFormInput,
    BFormSelectOption,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ValidationObserver,
    FormInput,
    FormInputWrap,
    LoaderButton,
    MultipleSelect,
    Multiselect,
    DropDownFilter
  },
  props: {
    validationSchema: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      isSubmitting: false,
      isEditor: false,
      formData: {
        name: '',
        advertiser_id: [],
        chance: null,
        tag: '',
        cookie_matching_id: 1,
        advertisement_types: [],
        daily_limit_user: null,
        domain_whitelist: null,
        domain_blacklist: null,
        device_types: null,
        geos: null,
        frequency: null,
        whitelist_geo: [],
        blacklist_geo: [],
        main_cpm: null,
        additional_param: null,
        oses: [],
        weight: 0,
        max_daily_requests_per_ip: null,
        max_days_with_continuous_impressions: null,
        max_impressions_per_day: null,
        timeout_on_continuous_limit_reached: null,
        limit_init_day: null,
        browsers: [],
        geo_limits: {},
        geo_distribution: [],

      },
      whitelistGeoBadges: [],
      advertisementTypes: [],
      paymentModel: [{
        value: 1,
        text: 'Фиксированный СРМ'
      }],
      blacklistType: 'blacklist',
      roleId: null,
      ...validations
    }
  },
  computed: {
    isContinuousRequired() {
      return (this.formData.max_impressions_per_day || this.formData.max_days_with_continuous_impressions || this.formData.timeout_on_continuous_limit_reached)
          ? '|required' : ''
    }
  },
  methods: {
    async handleSubmit() {

      const isValid = await this.$refs.form.validate();

      if(isValid) {

        const { geo_distribution, ...formData } = this.formData;
        const requestData = {
          ...formData,
          domain_whitelist: this.blacklistType === 'whitelist' ? this.formData.domain_whitelist : null,
          domain_blacklist: this.blacklistType === 'blacklist' ? this.formData.domain_blacklist : null,

        }

        for (let prop in requestData) {
          if(prop === 'geo_limits' || prop === 'copying') continue;
          if (Array.isArray(requestData[prop])) {
            requestData[prop] = requestData[prop].join(',')
          }
        }
        requestData.geo_limits = Object.keys(this.formData.geo_limits).map((key) => {
          return {
            region_id: key,
            daily_limit: this.formData.geo_limits[key][0],
            global_limit: this.formData.geo_limits[key][1]
          }
        })

        requestData.geo_distributions = this.formData.geo_distribution.map((el) => {
          return {
            region_id: el.code,
            percent: el.percent
          }
        })

        this.isSubmitting = true

        if (!this.isEditor) {
          createCampaign(requestData)
              .then(() => {
                this.$emit('success')
                this.$toast(getToastificationSuccessParams('РК успешно создана'))
              })
              .catch((e) => {
                this.$toast(getToastificationErrorParams(e.message))
                this.$refs.form.setErrors(e.response.data.errors);
              })
              .finally(() => {
                this.isSubmitting = false;
              })
        } else {
          patchCampaign(this.campaign.id, requestData)
              .then(() => {
                this.$emit('success')
                this.$toast(getToastificationSuccessParams('РК успешно отредактирована'))
              })
              .catch((e) => {
                this.$toast(getToastificationErrorParams(e.message))
                this.$refs.form.setErrors(e.response.data.errors);
              })
              .finally(() => {
                this.isSubmitting = false;
              })
        }
      }
    },

    clearContinuousLimit() {
      this.formData.max_days_with_continuous_impressions = null;
      this.formData.max_impressions_per_day = null;
      this.formData.timeout_on_continuous_limit_reached = null;
    },

    pickMultiselect(value, name) {
      this.formData[name] = value;
    },

    pickDsp(value) {
      this.formData.advertiser_id = value;
    },

    disableForAdvertisementType(el) {
      if(this.formData.advertisement_types.length === 0) return false;
      switch(el.id){
        case 1:
        case 2:
          if(this.formData.advertisement_types.includes(3) || this.formData.advertisement_types.includes(4)) {
            return true
          }
          break;
        case 3:
          if(this.formData.advertisement_types.includes(1) || this.formData.advertisement_types.includes(2) || this.formData.advertisement_types.includes(4))
            return true
              break;
        case 4:
          if(this.formData.advertisement_types.includes(1) || this.formData.advertisement_types.includes(2) || this.formData.advertisement_types.includes(3))
            return true
          break;
        default:
          if(this.formData.advertisement_types.includes(1) || this.formData.advertisement_types.includes(2)) return true
              break;
      }
    }
  },
  async created() {
    this.roleId = parseInt(localStorage.getItem('userRoleId'), 10)
    if (this.campaign && (this.campaign.id || this.campaign.copying)) {
      if(this.campaign.id) this.isEditor = true;
        this.formData = {
          name: this.campaign.name || '',
          chance: this.campaign.chance || null,
          advertiser_id: [this.campaign.advertiser.id] || [],
          tag: this.campaign.tag || '',
          geo_limits: this.campaign.geo_limits || {},
          geo_distribution: this.campaign.geo_distribution || [],
          cookie_matching_id: this.campaign.cookie_matching.id || null,
          timeout_on_continuous_limit_reached: this.campaign.timeout_on_continuous_limit_reached || null,
          max_impressions_per_day: this.campaign.max_impressions_per_day || null,
          max_days_with_continuous_impressions: this.campaign.max_days_with_continuous_impressions || null,
          daily_limit_user: this.campaign.daily_limit_user || null,
          advertisement_types: this.campaign.advertisement_types?.length && this.campaign.advertisement_types.map((item) => item.id) || [],
          domain_whitelist: this.campaign.domain_whitelist || '',
          domain_blacklist: this.campaign.domain_blacklist || '',
          device_types: this.campaign.device_types?.length && this.campaign.device_types.map((item) => item.id) || [],
          browsers: this.campaign.browsers?.length && this.campaign.browsers.map((item) => item.id) || [],
          // geos: this.campaign.geos?.length && this.campaign.geos.map((item) => item.id) || [],
          weight: this.campaign.weight || null,
          max_daily_requests_per_ip: this.campaign.max_daily_requests_per_ip || null,
          limit_init_day: this.campaign.limit_init_day || null,
          frequency: null,
          blacklist_geo: this.campaign.blacklist_geo?.length && this.campaign.blacklist_geo.map((item) => item.id) || [],
          whitelist_geo: this.campaign.whitelist_geo?.length && this.campaign.whitelist_geo.map((item) => item.id) || [],
          oses: this.campaign.oses?.length && this.campaign.oses.map((item) => item.id) || [],
          main_cpm: this.campaign.main_cpm || null,
          additional_param: this.campaign.additional_param || null,
        }
        if(this.campaign.domain_whitelist) this.blacklistType = 'whitelist';
    }
  },
  setup(props) {
    const {can} = useAclUtils();
    const isLoadingLists = ref(true)
    const filterToShow = ref('device_types')
    const filtersOptions = [
      {
        text: 'Тип устройства',
        value: 'device_types'
      },
      {
        text: 'ГЕО',
        value: 'geos'
      },
      {
        text: 'ОС',
        value: 'oses'
      },
      {
        text: 'Браузер',
        value: 'browsers'
      },
      {
        text: 'Whitelist/Blacklist домены',
        value: 'list_domain'
      },
      {
        text: 'Региональные лимиты',
        value: 'limits'
      },
      {
        text: 'Региональное распределение',
        value: 'distribution'
      },
      {
        text: 'Продолжительный лимит',
        value: 'continuous_limit'
      }
    ]

    const {
      advertisersList,
      advertisementTypesList,
      devicesList,
      geoWhiteList,
      geoBlackList,
      osList,
      browsersList,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList,
      namedLists
    } = formsLists()

    const cookieMatchings = ref([])

    if(props.campaign && Object.keys(props.campaign).length) {
          const blacklist_geo = props.campaign.blacklist_geo?.length && props.campaign.blacklist_geo.map((item) => item.id) || '';
          const whitelist_geo = props.campaign.whitelist_geo?.length && props.campaign.whitelist_geo.map((item) => item.id) || '';
          const oses = props.campaign.oses?.length && props.campaign.oses.map((item) => item.id) || '';
          const browsers =  props.campaign.browsers?.length && props.campaign.browsers.map((item) => item.id) || '';
          const dsps = props.campaign.advertiser?.id || '';
          //implement dsps loading here

      handleLoadLists(
          [['whitelist_geo', `geos=${whitelist_geo}`], ['blacklist_geo', `geos=${blacklist_geo}`],
            ['oses', `oses=${oses}`], ['browsers', `browsers=${browsers}`], ['dsps', `dsps=${dsps}`]]
          , isLoadingLists)
    } else {
      handleLoadLists(
          ['whitelist_geo', 'blacklist_geo',
            'oses', 'browsers', 'dsps']
          , isLoadingLists)
    }

    Promise.all([
      fetchAdvertisementType(),
      fetchDeviceList(),
      fetchCookieMatchingList()])
        .then(values => {
          advertisementTypesList.value = values[0].data.data
          devicesList.value = values[1].data.data
          cookieMatchings.value = values[2].data.data

        })

    const setFilterToShow = (value) => {
      filterToShow.value = value;
    }


    return {
      can,
      filterToShow,
      filtersOptions,
      advertisersList,
      advertisementTypesList,
      devicesList,
      geoWhiteList,
      geoBlackList,
      osList,
      browsersList,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList,
      namedLists,
      setFilterToShow,
      isLoadingLists,
      cookieMatchings
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.campaign-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'input input filter1 filter2'
  'input input ips additional'
  'input input chance cookie'
  'box box box box'
  'region region region region'
  'submit submit submit submit';
  grid-gap: 20px;

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }

  & .multiselect__items {
    height: 215px;
  }

  &__select-col {
    padding-right: 2rem;
  }


  &__cookie {
    grid-area: cookie;
  }

  &__chance {
    grid-area: chance;
  }

  &__ips {
    grid-area: ips;
  }

  &__submit {
    grid-area: submit;
  }

  &__multiselect {
    grid-area: box;

    &-leftcol {
      position: relative;
    }

    &-label {
      position: absolute;
      top: -26px;
      left: 14px;
    }
  }

  &__regions {
    grid-area: region;
  }

  &__adv-types {
    grid-area: filter2;
  }

  &__additional {
    grid-area: additional;
  }

  &__dsps {
    grid-area: filter1;

    & .multiselect {

      &__container._small {
        max-height: 230px;

        & .multiselect__items {
            height: 130px;
        }
      }
    }
  }
}
.campaign__dropdown {
  width: 100%;
}

.campaign__dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.selected-item {
  margin: 3px;
  cursor: pointer;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}
</style>
