<template>
<!--<b-row class="min-height-360 mt-3 campaign-form__regions">-->
<!--<b-col class="campaign-form__multiselect-leftcol" id="to-regions" sm="3">-->
<!--  <label for="to-regions" class="campaign-form__multiselect-label">Региональные лимиты</label>-->
<!--  <CountrySelect :model-value="country" @update:model-value="setCountry"-->
<!--                  :filters-options="geoList"-->
<!--  />-->
<!--</b-col>-->


<!--<b-col sm="9">-->
  <b-row class="d-flex" style="gap: 20px">
    <RegionSelect
        class="col pr-0 mt-1 mt-md-0 campaign-form__dsps"
        name="regions"
        :facets="regionsList"
        track-by="id"
        is-paginated
        :lists="namedLists"
        :state="formData.geo_limits"
        @paginate="handlePaginateList"
        @search="handleListsSearch"/>

  </b-row>
<!--</b-col>-->
<!--</b-row>-->
</template>

<script>
import {ref} from "@vue/composition-api"
import CountrySelect from "@/components/CountrySelect"
import RegionSelect from "@/components/RegionSelect"
import statisticLists from "@/use/statisticLists"
import {fetchRegionsList} from "@/api/lists"
import {objToQuery} from "@core/utils/utils"
import formsLists from "@/use/formsLists"

export default {
  name: 'RegionLimits',
  components: {
    CountrySelect,
    RegionSelect,
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
  },

  setup(props) {
    const {
      geoList,
      regionsList,
      namedLists,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList
    } = formsLists()

    const country = ref('RU');

    let regionsQuery = 'geo_id=RU';

    if(props.formData && props.formData.geo_limits) {
      regionsQuery += `&regions=${Object.keys(props.formData.geo_limits).join(',')}`
    }

    handleLoadLists([['geos', 'nameScope=Russia'], ['regions', regionsQuery]])


    const setCountry = (value, name) => {
      // isLoadingLists.value = true
      country.value = value;

      fetchRegionsList(objToQuery({'geo_id': [country.value]}))
          .then((res) => {
            regionsList.value = res.data.data
          })
    }

    return {
      geoList,
      regionsList,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList,
      country,
      setCountry,
      namedLists
    }
  }

}
</script>
