<template>
  <div v-if="isLoadingLists" class="d-flex justify-content-center align-items-center mt-5">
    <b-spinner variant="primary" label="Spinning" />
  </div>
  <validation-observer v-else ref="form" v-slot="{ invalid }">
    <BForm class="py-2" @submit.prevent="handleSubmit">


      <MultiselectValidation
          v-if="can('read', 'placements-list')"
          :rules="validationSchema.placements"
          validate-name="placements"
          :facets="placementsList"
          :on-change="pickMultiselect"
          is-searchable
          :state="formData.placements"
          track-by="id"
          name="placements"
          label="Размещения"
      />

      <div class="py-2">
        <loader-button
            type="submit"
            variant="primary"
            :disabled="isSubmitting"
            :is-submitting="isSubmitting"
        >
          {{ isEditor ? 'Сохранить' : 'Создать' }}
        </loader-button>
      </div>

    </BForm>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BSpinner
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LoaderButton from '@/components/LoaderButton'
import {useUtils as useAclUtils} from "@core/libs/acl"

import * as validations from '@validations'
import { ValidationProvider } from 'vee-validate'
import {
 fetchPlacementsList
} from '@/api/lists'
import {patchCampaign} from "@/api/campigns"
import MultiselectValidation from "@/components/MultiselectWithValidation"
import {getToastificationErrorParams, getToastificationSuccessParams} from "@/helpers/toasts"

export default {
  components: {
    MultiselectValidation,
    ValidationProvider,
    BForm,
    BSpinner,
    ValidationObserver,
    LoaderButton
  },
  props: {
    validationSchema: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      isSubmitting: false,
      isEditor: false,
      isLoadingLists: false,
      formData: {
        name: '',
        advertiser_id: [],
        tag: '',
        cookie_matching_id: null,
        advertisement_types: [],
        domain_whitelist: null,
        domain_blacklist: null,
        device_types: null,
        geos: null,
        frequency: null,
        whitelist_geo: [],
        blacklist_geo: [],
        main_cpm: null,
        oses: [],
        weight: 0,
        limit_init_day: null,
        browsers: [],
        placements: null
      },
      whitelistGeoBadges: [],
      advertisementTypes: [],
      paymentModel: [{
        value: 1,
        text: 'Фиксированный СРМ'
      }],
      blacklistType: 'blacklist',
      cookieMatchings: [],
      advertisersList: [],
      platformsList: [],
      placementsList: [],
      geoList: [],
      browsersList: [],
      osList: [],
      deviceList: [],
      roleId: null,
      ...validations
    }
  },
  methods: {
    async handleSubmit() {

      const isValid = await this.$refs.form.validate();

      if(isValid) {

        const requestData = {
          ...this.formData,
          domain_whitelist: this.blacklistType === 'whitelist' ? this.formData.domain_whitelist : null,
          domain_blacklist: this.blacklistType === 'blacklist' ? this.formData.domain_blacklist : null
        }

        for (let prop in requestData) {
          if (Array.isArray(requestData[prop])) {
            requestData[prop] = requestData[prop].join(',')
          }
        }

        this.isSubmitting = true

        patchCampaign(this.campaign.id, requestData)
            .then(() => {
              this.$emit('success')
              this.$toast(getToastificationSuccessParams('РК успешно отредактирована'))
            })
            .catch((e) => {
              this.$toast(getToastificationErrorParams(e.message))
            })
            .finally(() => {
              this.isSubmitting = false;
            })

      }
    },

    pickMultiselect(value, name) {
      this.formData[name] = value;
    }
  },
  async created() {
    this.roleId = parseInt(localStorage.getItem('userRoleId'), 10)
    if (this.campaign.id) {
      this.isEditor = true;
      this.formData = {
        name: this.campaign.name || '',
        advertiser_id: [this.campaign.advertiser.id] || [],
        tag: this.campaign.tag || '',
        cookie_matching_id: this.campaign.cookie_matching.id || null,
        advertisement_types: this.campaign.advertisement_types?.length && this.campaign.advertisement_types.map((item) => item.id) || [],
        domain_whitelist: this.campaign.domain_whitelist?.length && this.campaign.domain_whitelist.map((item) => item.name) || [],
        domain_blacklist: this.campaign.domain_blacklist?.length && this.campaign.domain_blacklist.map((item) => item.name) || [],
        device_types: this.campaign.device_types?.length && this.campaign.device_types.map((item) => item.id) || [],
        browsers: this.campaign.browsers?.length && this.campaign.browsers.map((item) => item.id) || [],
        // geos: this.campaign.geos?.length && this.campaign.geos.map((item) => item.id) || [],
        weight: this.campaign.weight || null,
        limit_init_day: this.campaign.limit_init_day || null,
        placements: this.campaign.placements?.length && this.campaign.placements.map((item) => item.id) || [],
        frequency: null,
        blacklist_geo: this.campaign.blacklist_geo?.length && this.campaign.blacklist_geo.map((item) => item.id) || [],
        whitelist_geo: this.campaign.whitelist_geo?.length && this.campaign.whitelist_geo.map((item) => item.id) || [],
        oses: this.campaign.oses?.length && this.campaign.oses.map((item) => item.id) || [],
        main_cpm: this.campaign.main_cpm || null
      }

    }

    this.isLoadingLists = true;


      fetchPlacementsList(null, this.roleId)
        .then(value => {
          this.placementsList = value?.data?.data
        })
        .finally(() => {
          this.isLoadingLists = false;
        })
  },
  setup() {
    const {can} = useAclUtils();

    return {
      can
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.campaign__dropdown {
  width: 100%;
}

.campaign__dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.selected-item {
  margin: 3px;
  cursor: pointer;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}
</style>
