<template>
  <vue-simple-scrollbar>
  <div class="countries">

    <div class="filters__filter" v-for="filter in filtersOptions">
      <b-list-group-item class="d-flex text-nowrap" @click="setFilter(filter.id)" :active="value === filter.id">
        {{ filter.name }}
<!--        <div class="ml-1 w-100">-->
<!--          <FilterBadges :facets="facets[filter.value]" :state="state[filter.value]" :name="filter.value"></FilterBadges>-->
          <!--      <BBadge v-for="badge in state[filter.value]">{{badge}}</BBadge>-->
<!--        </div>-->
      </b-list-group-item>
    </div>
  </div>
  </vue-simple-scrollbar>
</template>

<script>
import {BBadge, BListGroup, BListGroupItem} from "bootstrap-vue"
import FilterBadges from "@/components/FilterBadges"
import VueSimpleScrollbar from 'vue-simple-scrollbar'

export default {
  name: 'CountrySelect',
  components: {
    FilterBadges,
    BBadge,
    BListGroupItem,
    VueSimpleScrollbar
  },

  props: {
    filtersOptions: {
      type: Array,
      default: () => []
    },
    state: {
      type: Object,
      default: () => {}
    },
    facets: {
      type: Object,
      default: () => {}
    },
    modelValue: {
      type: String,
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    }
  },

  methods: {
    setFilter(event) {
      this.value = event;
    }
  }
}
</script>

<style lang="scss">
.countries {
  height: 160px;

  & .filters__filter:first-child {
    & .list-group-item {
      border-top-left-radius: 0.357rem;
      border-top-right-radius: 0.357rem;
    }
  }
  & .filters__filter:last-child {
    & .list-group-item:last-child {
      border-bottom-left-radius: 0.357rem;
      border-bottom-right-radius: 0.357rem;
    }
  }
}


.list-group-item {

  &.active {

    & .badge {
      background-color: white;
      color: #7367f0;
    }
  }
}
</style>
