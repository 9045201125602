<template>
  <validation-provider v-slot='{ errors }' :name='label' :vid='vid' :rules='rules'>
    <b-form-group :label='label' :label-for='id'>
      <b-form-textarea
          :id="id"
          v-model='value'
          :placeholder="placeholder"
          rows="3"
          max-rows="6"
          :state='errors.length > 0 ? false : null'
      ></b-form-textarea>
      <small class='text-danger'>{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>

import { BFormGroup, BFormTextarea } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'TextAreaValidation',
  props: {
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    vid: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
      default: undefined
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  components: {
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e)
    }
  }
}

</script>
