import api from '../libs/axios'

export const fetchCampaigns = (query) => api.get(`/api/campaign${query ? `?${query}` : ''}`)

export const createCampaign = (data) => api.post('/api/campaign', data)

export const patchCampaign = (id, data) => api.patch(`/api/campaign/${id}`, {...data})

export const activateCampaign = (id) => api.post(`/api/campaign/${id}/activate`)

export const deactivateCampaign = (id) => api.post(`/api/campaign/${id}/deactivate`)

export const deleteCampaign = (id) => api.delete(`/api/campaign/${id}`)
